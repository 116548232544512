import { type Ref, computed, toValue, watchEffect } from 'vue'

import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'

import { useMarketPaymentMethods } from '../../methods/composables/useMarketPaymentMethods'

import { useSignifyd } from './useSignifyd'

export const FraudDetectionStep = {
  IDLE: 'IDLE',
  CHECKOUT_IN_PROGRESS: 'CHECKOUT_IN_PROGRESS',
  CHECKOUT_ENDED: 'CHECKOUT_ENDED',
} as const

export type FraudDetectionStep = UnionOfPropertyTypes<typeof FraudDetectionStep>

type UseFraudDetectionScriptsOptions = {
  /**
   *
   */
  currentStep: Ref<FraudDetectionStep>
}

/**
 * Loads the fraud scripts needed for the current market payment methods.
 * Depending on the fraud partner, a script may be needed on other pages than the payment page.
 */
export const useFraudDetectionScripts = ({
  currentStep,
}: UseFraudDetectionScriptsOptions) => {
  if (import.meta.server) {
    return
  }
  const { data: methods } = useMarketPaymentMethods()

  const isSignifydEnabled = computed(() =>
    (methods.value ?? []).some(
      (method) => method.fraudCheckPartner === 'signifyd',
    ),
  )

  const { load: loadSignifyd, reset: resetSignifyd } = useSignifyd()

  watchEffect(async () => {
    if (!isSignifydEnabled.value) {
      return
    }

    switch (toValue(currentStep)) {
      case 'CHECKOUT_IN_PROGRESS':
        await loadSignifyd()
        break
      case 'CHECKOUT_ENDED':
        // Signifyd session ID should be reset once the payment is done.
        // Some user never reach the payment result page, but this + the short lifetime of the cookie should be enough.
        await resetSignifyd()
        break
      default:
        break
    }
  })
}
