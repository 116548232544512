export default {
  shipTo: {
    id: 'funnel_summary_addresses_card_ship_to',
    defaultMessage: 'Ship to {fullName}',
  },
  billTo: {
    id: 'funnel_summary_addresses_card_bill_to',
    defaultMessage: 'Bill to {fullName}',
  },
  modify: {
    id: 'funnel_summary_addresses_card_modify',
    defaultMessage: 'Modify',
  },
}
