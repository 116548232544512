export default {
  titleWithoutDetails: {
    id: 'funnel_summary_title_without_details',
    defaultMessage: 'Summary',
  },
  titleWithDetails: {
    id: 'funnel_summary_title_with_details',
    defaultMessage: 'Your order',
  },
  swapMultipleItemsTitle: {
    id: 'funnel_summary_swap_multiple_items_title',
    defaultMessage: 'cart',
  },
}
