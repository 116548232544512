<template>
  <ErrorBoundary :owners="['bot-squad-payin-front']">
    <div class="border-static-default-low rounded-sm border p-8">
      <PaymentIcons :methods="data || undefined" />
      <span
        class="text-static-default-low caption-bold mt-4 flex justify-center"
        ><IconLockLocked size="16" />
        {{ i18n(translations.securePayment) }}</span
      >
    </div>

    <PaymentAdvertisement
      v-if="withPaymentAvailability"
      :base-price="bagPrice"
      :payment-methods="data"
      spot="cart"
    />
  </ErrorBoundary>
</template>

<script lang="ts" setup>
import { type Price } from '@backmarket/http-api'
import { PaymentAdvertisement } from '@backmarket/nuxt-layer-payment/advertising'
import {
  PaymentIcons,
  useMarketPaymentMethodsFor,
} from '@backmarket/nuxt-layer-payment/methods'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import ErrorBoundary from '@backmarket/nuxt-module-logger/components/ErrorBoundary.vue'
import { IconLockLocked } from '@ds/icons/IconLockLocked'

import translations from './PaymentMethodsWrapper.translations'

const props = withDefaults(
  defineProps<{
    withPaymentAvailability: boolean
    listings?: string[]
    bagPrice: Price
    hasInsurance?: boolean
    tokenization?: boolean
  }>(),
  {
    listings: undefined,
    hasInsurance: false,
    tokenization: false,
  },
)

const i18n = useI18n()

const { data } = await useMarketPaymentMethodsFor({
  listings: () => props.listings || [],
  bagPrice: () => props.bagPrice,
  hasInsurance: () => props.hasInsurance,
  tokenization: () => props.tokenization,
})
</script>
