import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  securePayment: {
    id: 'cart_secure_payment',
    defaultMessage: 'Secure payment',
  },
}

export default translations
