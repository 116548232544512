<template>
  <NuxtLayout>
    <div
      class="bg-surface-default-mid absolute inset-x-0 top-0 flex size-full flex-col items-stretch justify-stretch overflow-hidden pt-60"
    >
      <CheckoutHeader class="fixed inset-x-0 top-0 h-60" />

      <ShippingAlertBanner
        v-if="showShippingAlertBanner"
        :alert="firstShippingAlert"
      />

      <Transition
        enter-active-class="opacity-100 transition-opacity duration-[250ms] ease-[ease-out]"
        enter-class="opacity-0"
        leave-active-class="opacity-0 transition-opacity delay-[250ms] ease-[ease-out]"
      >
        <div
          ref="page"
          class="relative overflow-scroll px-24 pb-[140px] md:flex md:grow md:flex-row md:items-stretch md:justify-stretch md:p-0"
        >
          <RevLoadingScreen
            v-if="!isLoaded"
            class="bg-surface-default-mid fixed inset-0 z-10 w-full md:absolute md:right-[440px]"
            data-qa="loading-screen"
            :text="loaderStore.message"
          />
          <div
            ref="mainContent"
            class="md:grow md:overflow-y-auto md:overflow-x-hidden md:px-24 md:pb-56"
          >
            <div
              class="mt-24 md:mx-auto md:mb-0 md:mt-48 md:w-full md:max-w-[820px]"
            >
              <div v-if="shouldShowContent" class="space-y-24 md:space-y-56">
                <RevStepper
                  v-if="activeStepName"
                  :active-step="activeStepName"
                  :alternative-text-back="i18n(translations.previousPage)"
                  :alternative-text-close="i18n(translations.stepperClose)"
                  :alternative-text-completed="
                    i18n(translations.stepperCompleted)
                  "
                  :alternative-text-current="i18n(translations.stepperCurrent)"
                  has-back
                  :steps
                  @back="handleStepBack"
                />

                <NuxtPage />
              </div>
            </div>
          </div>
          <div
            v-if="shouldShowCheckoutSummary"
            class="pt-40 md:bg-surface-default-hi md:w-[27.5rem] md:overflow-y-auto md:overflow-x-hidden md:px-24 md:pt-48"
          >
            <CheckoutSummary
              :with-addresses="route.name === CHECKOUT.PAYMENT"
              :with-details="route.name !== CHECKOUT.CART"
              :with-extended-legal="route.name !== CHECKOUT.CART"
              :with-payment-availability="route.name === CHECKOUT.CART"
              :with-swap-advertisement="route.name === CHECKOUT.CART"
            />
          </div>
        </div>
      </Transition>
    </div>
    <TheToast />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useHead, useRoute, useRouter } from '#imports'
import { computed, onErrorCaptured, onMounted, ref, watch } from 'vue'

import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import {
  FraudDetectionStep,
  useFraudDetectionScripts,
} from '@backmarket/nuxt-layer-payment/fraud/composables/useFraudDetectionScripts'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import TheToast from '@backmarket/nuxt-module-toast/TheToast.vue'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'
import { RevStepper } from '@ds/components/Stepper'
import { storeToRefs } from 'pinia'

import BouyguesStepperLogo from '~/components/BouyguesStepperLogo/BouyguesStepperLogo.vue'
import { useSwapStore } from '~/scopes/checkout/stores/swapStore'

import CheckoutHeader from '../components/CheckoutHeader.vue'
import CheckoutSummary from '../components/CheckoutSummary.vue'
import ShippingAlertBanner from '../components/ShippingAlertBanner.vue'
import useEnhanceLogError from '../composables/useEnhancedLogError'
import useHandleUnauthorizedUser from '../composables/useHandleUnauthorizedUser'
import { CHECKOUT } from '../routes-names'
import { useAddressStore } from '../stores/addressStore'
import { useCartStore } from '../stores/cartStore'
import { useDiscountStore } from '../stores/discountStore'
import { useLoaderStore } from '../stores/loaderStore'

import {
  CHECKOUT_ACTIVE_STEPS,
  CHECKOUT_PREVIOUS_ROUTES,
} from './CheckoutLayout.constant'
import translations from './CheckoutLayout.translations'

const addressStore = useAddressStore()
const cartStore = useCartStore()
const discountStore = useDiscountStore()
const errorLogger = useEnhanceLogError()
const i18n = useI18n()
const currentLocale = useI18nLocale()
const loaderStore = useLoaderStore()
const route = useRoute()
const router = useRouter()
const { logSwapDesync } = useBuybackOffer()
const { offer } = storeToRefs(useSwapStore())

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const mainContent = ref()
const page = ref()

useHead({
  title: i18n(translations.title),
  htmlAttrs: { lang: currentLocale },
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: i18n(translations.description),
    },
  ],
})

const previousRouteName = computed(() => {
  if (typeof route.name !== 'string') {
    return null
  }

  if (cartStore.bouyguesMobilePlan) {
    return {
      ...CHECKOUT_PREVIOUS_ROUTES,
      [CHECKOUT.PAYMENT]: CHECKOUT.SERVICES.BOUYGUES.PHONELINE_SETUP,
    }[route.name]
  }

  return CHECKOUT_PREVIOUS_ROUTES[route.name]
})

const bouyguesSteps = [
  {
    name: 'bouygues',
    label: i18n(translations.bouyguesStep),
    suffix: BouyguesStepperLogo,
  },
]

const steps = computed(() => {
  const hasBouyguesStep = !isEmpty(cartStore.bouyguesMobilePlan)

  return [
    {
      name: 'account',
      label: i18n(translations.accountStep),
    },
    {
      name: 'shipping',
      label: i18n(translations.shippingStep),
    },
    ...insertIf(hasBouyguesStep, bouyguesSteps),
    {
      name: 'payment',
      label: i18n(translations.paymentStep),
    },
    {
      name: 'confirmation',
      label: i18n(translations.confirmationStep),
    },
  ]
})

const activeStepName = computed(() => {
  const activeStep = steps.value.find(
    (step) => step.name === CHECKOUT_ACTIVE_STEPS[route.name as string],
  )

  return activeStep?.name
})

const shouldLoadCart = computed(
  () =>
    route.name !== CHECKOUT.SERVICES.BOUYGUES.SIGN_UP &&
    route.name !== CHECKOUT.SERVICES.BOUYGUES.AUTH &&
    route.name !== CHECKOUT.SERVICES.BOUYGUES.NOT_ELIGIBLE,
)

const shouldShowContent = computed(
  () => !shouldLoadCart.value || cartStore.isCartLoaded,
)

const isLoaded = computed(
  () =>
    !shouldLoadCart.value || (cartStore.isCartLoaded && !loaderStore.isEnabled),
)

const firstShippingAlert = computed(() => addressStore.shipping.warnings[0])

const showShippingAlertBanner = computed(
  () => route.meta.showShippingAlerts && !isEmpty(firstShippingAlert.value),
)

const shouldShowCheckoutSummary = computed(
  () =>
    shouldLoadCart.value &&
    cartStore.hasAvailableItems &&
    cartStore.isCartLoaded,
)

const handleWindowPopState = () => {
  loaderStore.disable()
}

onMounted(async () => {
  try {
    if (shouldLoadCart.value) {
      loaderStore.enable()
      await cartStore.fetchCart()
      logSwapDesync(offer.value && offer.value.standardPrice.amount)
    }
  } catch (error) {
    await handleUnauthorizedUser(error as Record<string, unknown>)
  } finally {
    loaderStore.disable()
    // Disable the loader when the user goes back to a Checkout page using the
    // browser's previous button.
    // Note: when doing so, on some browser (ex: Safari), the page is not fully
    // reloaded, so the `mounted` hook is not called.
    window.addEventListener('popstate', handleWindowPopState)
  }
})

onErrorCaptured((err, instance, info) => {
  // eslint-disable-next-line no-underscore-dangle
  const componentTag = instance?.$options?.__name || 'none'

  errorLogger({
    component: {
      name: componentTag,
      props: { ...instance?.$props },
    },
    routeName: route.name,
    err,
    info,
  })

  return true
})

const handleStepBack = () => {
  if (route.name === CHECKOUT.PAYMENT) {
    discountStore.setPaymentMethod(null)
  }

  if (previousRouteName.value) {
    router.push({ name: previousRouteName.value })
  }
}

useFraudDetectionScripts({
  currentStep: computed(
    (): FraudDetectionStep =>
      route.name !== CHECKOUT.PAYMENT_RESULT
        ? 'CHECKOUT_IN_PROGRESS'
        : 'CHECKOUT_ENDED',
  ),
})

watch(
  () => route.name,
  () => {
    if (page.value) {
      page.value.scrollTo(0, 0)
    }
    if (mainContent.value) {
      mainContent.value.scrollTo(0, 0)
    }
  },
)
</script>
