import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  enrollmentPeriod: {
    id: 'checkout_summary_bouygues_mobile_plan_enrollment_period',
    defaultMessage: '{duration} engagement',
  },
  firstPeriodPrice: {
    id: 'checkout_summary_bouygues_mobile_plan_first_period_price',
    defaultMessage: `{pricesCount, plural,
      =0 {}
      one {}
      other {from }
    }{price}/month`,
  },
  shippingLabel: {
    id: 'checkout_summary_bouygues_mobile_plan_shipping_label',
    defaultMessage: 'Shipping',
  },
  shippingPrice: {
    id: 'checkout_summary_bouygues_mobile_plan_shipping_price',
    defaultMessage: 'Free',
  },
}

export default translations
