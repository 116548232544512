<template>
  <RevCard class="flex flex-col gap-24 p-24" data-cs-mask>
    <div
      v-for="{ address, title, withPhoneNumber, to } in addresses"
      :key="title.id"
      class="flex flex-col items-start gap-8 md:flex-row"
    >
      <div class="grow">
        <Address :address compact with-details :with-phone="withPhoneNumber">
          <template #title="{ fullName }">
            {{ i18n(title, { fullName }) }}
          </template>
        </Address>
      </div>

      <RevButton
        v-if="to"
        size="small"
        :to="resolveLocalizedRoute(to)"
        variant="secondary"
      >
        {{ i18n(translations.modify) }}
      </RevButton>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'
import { computed } from 'vue'

import Address from '@backmarket/nuxt-module-address/Address.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'

import { CHECKOUT } from '../routes-names'
import { useAddressStore } from '../stores/addressStore'

import translations from './CheckoutSummaryAddressesCard.translations'

const i18n = useI18n()
const addressStore = useAddressStore()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const addresses = computed(() => {
  return [
    addressStore.hasCollectionPoint
      ? {
          title: translations.shipTo,
          address: {
            ...addressStore.collectionPoint,
            ...addressStore.collectionPoint?.collectionPoint,
            company: addressStore.collectionPoint?.collectionPoint?.name,
          },
          withPhoneNumber: true,
        }
      : {
          title: translations.shipTo,
          address: addressStore.shipping,
          withPhoneNumber: true,
          to: { name: CHECKOUT.SHIPPING_ADDRESS },
        },
    {
      title: translations.billTo,
      address: addressStore.billing,
      withPhoneNumber: false,
      to: { name: CHECKOUT.BILLING_ADDRESS },
    },
  ]
})
</script>
