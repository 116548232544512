export default {
  titleCovid19: {
    id: 'shipping_warnings_banner_title_covid_19',
    defaultMessage: 'Difficult delivery conditions',
  },
  definitionCovid19: {
    id: 'shipping_warnings_banner_definition_covid_19',
    defaultMessage:
      '{title, html}: Due to the Covid-19 crisis, some carriers are currently experiencing difficulties in delivering to your area. However, we will do our utmost to deliver to you as quickly as possible.',
  },
  titleFlooding: {
    id: 'shipping_warnings_banner_title_flooding',
    defaultMessage: 'Difficult delivery conditions',
  },
  definitionFlooding: {
    id: 'shipping_warnings_banner_definition_flooding',
    defaultMessage:
      '{title, html}: Due to the major flooding in Michigan, some carriers are currently experiencing difficulties in delivering to your area. However, we will do our utmost to deliver to you as quickly as possible.',
  },
  titlePaymentDown: {
    id: 'shipping_warnings_banner_title_payment_down',
    defaultMessage: "Payments are currently down, but we're working on it.",
  },
  definitionPaymentDown: {
    id: 'shipping_warnings_banner_definition_payment_down',
    defaultMessage: '{title, html}',
  },
}
