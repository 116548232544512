<template>
  <div class="space-y-8">
    <div class="space-y-4">
      <div class="body-2-bold flex gap-4">
        <BouyguesLogo :size="16" />

        <p>{{ offer.label }}</p>
      </div>

      <p class="body-2">
        <EnrollmentPeriod
          :enrollment-period="offer.enrollmentPeriod"
          :enrollment-translation="translations.enrollmentPeriod"
        />
      </p>

      <p v-if="priceOption && firstPeriodPrice" class="body-2">
        {{
          i18n(translations.firstPeriodPrice, {
            price: firstPeriodPrice,
            pricesCount: priceOption.prices.length,
          })
        }}
      </p>
    </div>

    <div class="flex justify-between gap-24">
      <p class="body-2">{{ i18n(translations.shippingLabel) }}</p>
      <p class="body-2">{{ i18n(translations.shippingPrice) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import BouyguesLogo from '~/components/BouyguesLogo/BouyguesLogo.vue'
import EnrollmentPeriod from '~/scopes/checkout/components/EnrollmentPeriod/EnrollmentPeriod.vue'
import { useBouyguesStore } from '~/scopes/checkout/stores/bouyguesStore'

import translations from './BouyguesMobilePlan.translations'

const props = defineProps<{
  offer: MobilePlanOffer
}>()

const i18n = useI18n()
const bouyguesStore = useBouyguesStore()

const priceOption = computed(() => {
  const defaultOption = 'KEEP_PHONE_NUMBER'
  const selectedOption =
    bouyguesStore.phonelineSetupInformation?.offerOption || defaultOption

  return props.offer.priceOptions.find(
    ({ option }) => option === selectedOption,
  )
})

const firstPeriodPrice = computed(() => {
  if (!priceOption.value) return null

  const price = priceOption.value.prices[0]?.price

  return price ? i18n.price(price) : null
})
</script>
