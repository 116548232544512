export default {
  bouyguesStep: {
    id: 'funnel_page_step_bouygues',
    defaultMessage: 'Bouygues Telecom set up',
  },
  accountStep: {
    id: 'funnel_page_step_account',
    defaultMessage: 'Account',
  },
  shippingStep: {
    id: 'funnel_page_step_shipping',
    defaultMessage: 'Shipping',
  },
  paymentStep: {
    id: 'funnel_page_step_payment',
    defaultMessage: 'Payment',
  },
  confirmationStep: {
    id: 'funnel_page_step_confirmation',
    defaultMessage: 'Confirmation',
  },
  previousPage: {
    id: 'funnel_page_previous_page',
    defaultMessage: 'Back to previous page',
  },
  stepperClose: {
    id: 'funnel_page_stepper_close',
    defaultMessage: 'Close',
  },
  stepperCompleted: {
    id: 'funnel_page_stepper_completed',
    defaultMessage: 'Completed',
  },
  stepperCurrent: {
    id: 'funnel_page_stepper_current',
    defaultMessage: 'Current',
  },
  title: {
    id: 'funnel_page_title',
    defaultMessage: 'Your green cart | Back Market',
  },
  description: {
    id: 'funnel_page_description',
    defaultMessage:
      'Fill your cart with refurbished products on Back Market. Save cash by spending less on the good stuff, backed by warranties.',
  },
  cartStep: {
    id: 'funnel_page_step_cart',
    defaultMessage: 'Cart',
  },
}
