<template>
  <div class="ml-4 p-3">
    <RevIllustration
      :alt="i18n(translations.bouyguesIcon)"
      :height="18"
      src="/img/bouygues/bouyguesLogoSquare.svg"
      :width="18"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './BouyguesStepperLogo.translations'

const i18n = useI18n()
</script>
