export default {
  shipping: {
    id: 'checkout_summary_shipping',
    defaultMessage: 'Shipping',
  },
  free: {
    id: 'checkout_summary_free',
    defaultMessage: 'Free',
  },
  subtotal: {
    id: 'checkout_summary_subtotal',
    defaultMessage: 'Subtotal',
  },
  estimatedTax: {
    id: 'checkout_summary_estimated_tax',
    defaultMessage: 'Estimated tax',
  },
  tax: {
    id: 'checkout_summary_tax',
    defaultMessage: 'Tax',
  },
  totalWithTaxes: {
    id: 'checkout_summary_total_with_taxes',
    defaultMessage: 'Total including taxes',
  },
  total: {
    id: 'checkout_summary_total',
    defaultMessage: 'Total',
  },
  totalDueToday: {
    id: 'checkout_price_title_due_today',
    defaultMessage: 'Total due today',
  },
  pending: {
    id: 'checkout_summary_pending',
    defaultMessage: 'Pending',
  },
  buttonCheckout: {
    id: 'checkout_summary_button_checkout',
    defaultMessage: 'Secure checkout',
  },
  installment: {
    id: 'checkout_summary_installment',
    defaultMessage: 'Installment fee',
  },
  swap: {
    id: 'checkout_summary_tradein_title',
    defaultMessage: 'Trade-in value',
  },
  mobilePlanSubsidy: {
    id: 'checkout_mobile_plan_subsidy',
    defaultMessage: '{partnerName} saved you {subsidy}',
  },
  totalPriceAfterBuyback: {
    id: 'checkout_price_title_after_trade_in',
    defaultMessage: 'Total after trade-in',
  },
  recurring: {
    id: 'summary_recurring_payment_info',
    defaultMessage:
      'Only the first round of recurring payments to Back Market is included in your total today.',
  },
}
