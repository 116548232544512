import { HOME } from '~/scopes/home/route-names'

import { CHECKOUT } from '../routes-names'

type CheckoutStep = 'cart' | 'shipping' | 'payment' | 'insurance' | 'bouygues'

export const CHECKOUT_ACTIVE_STEPS: Record<string, CheckoutStep> = {
  [CHECKOUT.ADDRESS_CONFIRMATION]: 'shipping',
  [CHECKOUT.SHIPPING_ADDRESS]: 'shipping',
  [CHECKOUT.BILLING_ADDRESS]: 'shipping',
  [CHECKOUT.PAYMENT]: 'payment',
  [CHECKOUT.INSURANCE]: 'insurance',
  [CHECKOUT.SERVICES.BOUYGUES.SIGN_UP]: 'bouygues',
  [CHECKOUT.SERVICES.BOUYGUES.PHONELINE_SETUP]: 'bouygues',
}

export const CHECKOUT_PREVIOUS_ROUTES = {
  [CHECKOUT.CART]: HOME,
  [CHECKOUT.ADDRESS_CONFIRMATION]: CHECKOUT.CART,
  [CHECKOUT.SHIPPING_ADDRESS]: CHECKOUT.CART,
  [CHECKOUT.BILLING_ADDRESS]: CHECKOUT.CART,
  [CHECKOUT.PAYMENT]: CHECKOUT.ADDRESS_CONFIRMATION,
  [CHECKOUT.SERVICES.BOUYGUES.SIGN_UP]: CHECKOUT.ADDRESS_CONFIRMATION,
  [CHECKOUT.SERVICES.BOUYGUES.PHONELINE_SETUP]:
    CHECKOUT.SERVICES.BOUYGUES.SIGN_UP,
  [CHECKOUT.INSURANCE]: CHECKOUT.CART,
}

export const CHECKOUT_NAVIGATION_GUARD = {
  emptyCart: [
    CHECKOUT.ADDRESS_CONFIRMATION,
    CHECKOUT.BILLING_ADDRESS,
    CHECKOUT.PAYMENT,
    CHECKOUT.SHIPPING_ADDRESS,
  ],
  missingShipping: [CHECKOUT.ADDRESS_CONFIRMATION, CHECKOUT.PAYMENT],
  missingCollectionPoint: [CHECKOUT.ADDRESS_CONFIRMATION, CHECKOUT.PAYMENT],
}
